import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Avatar, Layout, Typography, Select, Badge } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { RibbonProps } from 'antd/es/badge/Ribbon';
import { isNil } from 'ramda';

import * as Store from '@interfaces/stores';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { Ribbon } = Badge;

export const BasicContainer = styled(Layout).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})<{ display?: boolean }>`
  display: ${({ display = true }) => (display ? 'flex' : 'none')} !important;
`;

export const BasicSider = styled(Sider)`
  overflow-y: auto;
  height: 100vh;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  padding: 16px 0;
`;

export const BasicMain = styled(Layout).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['marginLeft'].includes(prop) && defaultValidatorFn(prop),
})<{ marginLeft: CSSProperties['marginLeft'] }>`
  ${({ marginLeft }) => css({ marginLeft })}
`;

export const BasicHeader = styled(Header).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['backgroundColor'].includes(prop) && defaultValidatorFn(prop),
})<{ backgroundColor?: string }>`
  padding: 0;
  background: ${(props) => props.backgroundColor};
`;

export const BasicTitleWrapper = styled.div`
  display: inline-flex;
  width: calc(100% - 66px);
  align-items: center;
`;

export const BasicTitle = styled(Title)`
  margin: 0 !important;
`;

export const BasicContent = styled(Content).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['backgroundColor', 'hasRibbon'].includes(prop) &&
    defaultValidatorFn(prop),
})<{ backgroundColor?: string; hasRibbon?: boolean }>`
  margin: 24px 16px;
  padding: 24px;
  padding-top: ${(props) => (props.hasRibbon ? '32px' : 'auto')};
  min-height: calc(100vh - ${() => 64 + 24 + 24}px);
  background: ${(props) => props.backgroundColor};
  overflow: initial;
`;

export const createTriggerIcon = (
  IconComp: typeof MenuFoldOutlined | typeof MenuUnfoldOutlined,
) => styled(IconComp).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['hoverColor'].includes(prop) && defaultValidatorFn(prop),
})<{ hoverColor: string }>`
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;

export const BasicAvatarWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BasicAvatar = styled(Avatar)`
  background-color: #fde3cf;
  color: #f56a00;
  margin-right: 24px;
`;

export const OrganizationSelect = styled(
  Select<Store.Managers.MeOrgSelectOption['value']>,
).withConfig({
  shouldForwardProp: (prop) => !['display'].includes(prop),
})<{ display?: boolean }>`
  min-width: 180px;
  display: ${(props) => (props.display ? 'inline-block' : 'none')};
`;

export const ContentRibbon = styled(Ribbon).withConfig({
  shouldForwardProp: (prop) => !['display'].includes(prop),
})<RibbonProps & { display?: boolean }>`
  display: ${({ display = true }) => (display ? 'block' : 'none')};
  top: 34px;
  inset-inline-start: ${(props) =>
    isNil(props.text)
      ? 'none'
      : props.placement === 'start'
      ? '8px'
      : 'none'} !important;
  inset-inline-end: ${(props) =>
    isNil(props.text)
      ? 'none'
      : props.placement === 'end'
      ? '8px'
      : 'none'} !important;
`;
