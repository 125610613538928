import { useState, useRef } from 'react';
import { pick } from 'ramda';

import { Managers, Utils } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import usePagination from '@hooks/usePagination';
import useVisible from '@hooks/useVisible';
import { getManagers } from '@apis/managers';
import { FilterValue } from '../components/Filter';

interface UseManagersOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useManagers = (props: UseManagersOptions = {}) => {
  const { onError, throwable } = props;
  const loading = useLoading();
  const pagination = usePagination();
  const visible = useVisible();
  const condition = useRef<Managers.FindManyManagerQuery>({});
  const [filterValue, setFilterValue] = useState<FilterValue>();
  const [data, setData] = useState<Managers.Manager[]>([]);

  const storeCondition = (filterValue: FilterValue) => {
    setFilterValue(filterValue);
    condition.current = filterValue;
    return filterValue;
  };

  const search = async (
    conditions: Managers.FindManyManagerQuery,
    page: Utils.Pagination['page'],
    size: Utils.Pagination['size'],
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, meta] = await getManagers({
          ...conditions,
          page,
          size,
          onlyDefaultOrg: true,
        });
        setData(data);
        const { total } = meta;
        pagination.changeTotal(total);
        return data;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  const fetch = async (filterValue: FilterValue = {}) => {
    const { page, size } = pick(['page', 'size'], pagination);
    const condition = storeCondition(filterValue);
    const data = await search(condition, page, size);
    return data;
  };

  const changePage = async (page: number, size: number) => {
    pagination.changePageAndSize(page, size);
    const data = await search({}, page, size);
    return data;
  };

  const reload = async () => {
    const { page, size } = pagination;
    const data = await search({}, page, size);
    return data;
  };

  const reset = async () => {
    const { page, size } = pagination.reset();
    const condition = storeCondition({});
    const data = await search(condition, page, size);
    return data;
  };

  return {
    ...visible,
    filterValue,
    loading: loading.loading,
    fetch,
    changePage,
    reload,
    reset,
    data,
    pagination,
  };
};

export default useManagers;
