export enum ManagerStatus {
  INVITED = 0,
  INVITATION_FAILED = 1,
  ACTIVATED = 2,
  DEACTIVATED = 3,
  DELETED = 4,
  FLUSHED_TOKEN = 5,
}

export enum SearchScope {
  ALL = 'all',
  MANAGER = 'mgr',
  CLUSTER = 'clstr',
}
