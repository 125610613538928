import z from 'zod';

import { SearchScope } from '@enums/organizations.enum';

export const SearchManyQuerySchema_SuperAdmin = z.object({
  keyword: z.string().min(1),
  scope: z.nativeEnum(SearchScope).optional().default(SearchScope.ALL),
  ignoreBp: z.boolean().optional(),
  exclude: z
    .object({
      org: z.string().array().optional(),
      clstr: z.string().array().optional(),
    })
    .optional(),
});

export const SearchManyRespSchema_SuperAdmin = z.object({
  id: z.string(),
  name: z.string(),
  type: z.union([
    z.literal(SearchScope.CLUSTER),
    z.literal(SearchScope.ORGANIZATION),
  ]),
});
