import { useMemo } from 'react';
import { Col, Space, Button, Table, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { SUPER_ADMIN_FETCH_MANY_ORGANIZATION_CLUSTER_ERROR } from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ReloadButton from '@components/ReloadButton';
import FilterButton from '@components/FilterButton';
import GoBackHeader from '@components/GoBackHeader';
import FluidRow from '@components/FluidRow';
import Filter from './components/Filter';
import { Container } from './styleds';
import useOrganizationCluster from './hooks/useOrganizationCluster';
import { createMainTableColumns } from './columns';

const SuerAdminOrganizationCluster = () => {
  const navigate = useEnhancedNavigate();
  const { errorNotify } = useGlobalNotification();
  const clusters = useOrganizationCluster({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANY_ORGANIZATION_CLUSTER_ERROR),
  });

  const handleGoBackClick = () => {
    navigate(-1);
  };

  const handleAddClick = () => {
    navigate('editor');
  };

  const mainTableColumns = useMemo(() => createMainTableColumns(), []);

  return (
    <Spin spinning={clusters.loading}>
      <Filter
        value={clusters.filterValue}
        open={clusters.isOpen}
        onSearch={clusters.fetch}
        onReset={clusters.reset}
        onClose={clusters.close}
      />
      <Container direction="vertical" size="large">
        <GoBackHeader onGoBack={handleGoBackClick}>
          <FluidRow justify="space-between">
            <Col>
              <Space>
                <FilterButton count={clusters.count} onClick={clusters.open} />
                <ReloadButton onClick={clusters.reload} />
              </Space>
            </Col>
            <Col>
              <Button icon={<PlusOutlined />} onClick={handleAddClick}>
                Cluster
              </Button>
            </Col>
          </FluidRow>
        </GoBackHeader>
        <Table
          rowKey="id"
          scroll={{ x: 'max-content' }}
          columns={mainTableColumns}
          dataSource={clusters.data}
          pagination={{
            current: clusters.pagination.page,
            pageSize: clusters.pagination.size,
            total: clusters.pagination.total,
            onChange: clusters.changePage,
          }}
        />
      </Container>
    </Spin>
  );
};

export default SuerAdminOrganizationCluster;
