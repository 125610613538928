import { useMemo } from 'react';
import { Row, Col, Space, Button, Table, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
  SUPER_ADMIN_FETCH_MANAGERS_ERROR,
  REINVITE_ONE_MANAGER_SUCCESS,
  REINVITE_ONE_MANAGER_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ReloadButton from '@components/ReloadButton';
import FilterButton from '@components/FilterButton';
import Filter from './components/Filter';
import useManagers from './hooks/useManagers';
import useReinvite from './hooks/useReinvite';
import { createMainTableColumns } from './utils';
import { Container } from './styleds';

const SuperAdminManager = () => {
  const navigate = useEnhancedNavigate();
  const { successNotify, errorNotify } = useGlobalNotification();
  const managers = useManagers({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANAGERS_ERROR),
  });
  const reinvite = useReinvite({
    onSuccess: successNotify(REINVITE_ONE_MANAGER_SUCCESS),
    onError: errorNotify(REINVITE_ONE_MANAGER_ERROR),
  });

  const handleAddClick = () => {
    navigate('editor');
  };

  const handleEditClick = (mgrId: string) => {
    navigate(`editor/${mgrId}`);
  };

  const handleClusterClick = () => {
    navigate('clusters');
  };

  const handleReinviteClick = async (mgrId: string) => {
    await reinvite.submit(mgrId);
  };

  const mainTableColumns = useMemo(
    () =>
      createMainTableColumns({
        onEditClick: handleEditClick,
        onReinviteClick: handleReinviteClick,
      }),
    [],
  );

  return (
    <Spin spinning={managers.loading}>
      <Filter
        value={managers.filterValue}
        open={managers.isOpen}
        onSearch={managers.fetch}
        onReset={managers.reset}
        onClose={managers.close}
      />
      <Container direction="vertical" size="large">
        <Row justify="space-between">
          <Col>
            <Space>
              <FilterButton onClick={managers.open} />
              <ReloadButton onClick={managers.reload} />
              <Button onClick={handleClusterClick}>Clusters</Button>
            </Space>
          </Col>
          <Col>
            <Space>
              <Button icon={<PlusOutlined />} onClick={handleAddClick}>
                Add Manager
              </Button>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey="id"
          scroll={{ x: 'max-content' }}
          columns={mainTableColumns}
          dataSource={managers.data}
          pagination={{
            current: managers.pagination.page,
            pageSize: managers.pagination.size,
            total: managers.pagination.total,
            onChange: managers.changePage,
          }}
        />
      </Container>
    </Spin>
  );
};

export default SuperAdminManager;
