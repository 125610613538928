import { TypeOf as ZodTypeOf, AnyZodObject } from 'zod';
import { isNotNil } from 'ramda';

export const pickDeepNotNil = <T extends ZodTypeOf<AnyZodObject>>(
  target: T,
) => {
  const result = {} as T;
  for (const key in target) {
    const value = target[key];
    if (isNotNil(value)) {
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          result[key] = value
            .filter((item: any) => isNotNil(item))
            .map((item: any) => {
              if (typeof item === 'object') return pickDeepNotNil(item);
              return item;
            });
        } else {
          console.log('12133');
          result[key] = pickDeepNotNil(value);
        }
      } else result[key] = value;
    }
  }
  return result;
};
