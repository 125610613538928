import { useState } from 'react';
import { Affix, GetProps } from 'antd';

import { StyledForm } from './styleds';

type FormProps = GetProps<typeof StyledForm>;

const AffixFormFooter = ({ children, ...props }: FormProps = {}) => {
  const [affixed, setAffixed] = useState<boolean>();

  return (
    <Affix offsetBottom={24} onChange={setAffixed}>
      <StyledForm {...props} children={children} padding={affixed} />
    </Affix>
  );
};

export default AffixFormFooter;
