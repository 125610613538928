import { cloneElement, isValidElement, MouseEventHandler } from 'react';
import {
  useHref,
  useLinkClickHandler,
  useLocation,
  Location,
} from 'react-router-dom';
import { Tooltip, Button, Dropdown, Space, Badge as NormalBadge } from 'antd';
import { isNil, isNotNil } from 'ramda';
import { MoreOutlined } from '@ant-design/icons';

import { TooltipButtonProps } from './interfaces';
import { WithDropdownBadge } from './styleds';

interface LocationState {
  prev?: string[];
}

const { Compact } = Space;

const TooltipButton = (props: TooltipButtonProps = {}) => {
  const {
    children,
    wrapper,
    title,
    navigate,
    target,
    onClick,
    dropdown,
    badge,
    ...buttonProps
  } = props;
  const location: Location<LocationState> = useLocation();
  const { pathname, search } = location;
  const state = location.state || {};
  const { to, ...linkProps } = navigate || {};
  const href = useHref(to || '#');

  const handleRouterLinkOnClick = useLinkClickHandler<HTMLElement>(to || '#', {
    ...linkProps,
    target: target || navigate?.target,
    state: {
      ...state,
      ...navigate?.state,
      prev: [`${pathname}${search}`, ...(state.prev || [])],
    },
  });

  const handleOnClick: MouseEventHandler<HTMLElement> = (event) => {
    onClick?.(event);
    if (!event.defaultPrevented) {
      handleRouterLinkOnClick(event);
    }
  };

  const withNavigateOrNot = isNotNil(navigate) ? (
    <Button
      {...buttonProps}
      href={isNotNil(buttonProps.href) ? href : undefined}
      target={target}
      onClick={handleOnClick}
    >
      {children}
    </Button>
  ) : (
    <Button {...buttonProps} target={target} onClick={onClick}>
      {children}
    </Button>
  );

  const Badge = isNotNil(dropdown) ? WithDropdownBadge : NormalBadge;

  const withBadgeOrNot = isNotNil(badge) ? (
    <Badge {...badge}>{withNavigateOrNot}</Badge>
  ) : (
    withNavigateOrNot
  );

  const withDropdownOrNot = isNotNil(dropdown) ? (
    <Compact>
      {withBadgeOrNot}
      <Dropdown trigger={['click']} {...dropdown}>
        <Button icon={<MoreOutlined />} />
      </Dropdown>
    </Compact>
  ) : (
    withBadgeOrNot
  );

  return (
    <Tooltip title={title}>
      {isNil(wrapper)
        ? withDropdownOrNot
        : isValidElement(wrapper)
        ? cloneElement(wrapper, {
            children: withDropdownOrNot,
          })
        : null}
    </Tooltip>
  );
};

export default TooltipButton;
export type { TooltipButtonProps };
