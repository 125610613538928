import { useState, useRef } from 'react';
import { isNil, isEmpty, pick } from 'ramda';

import { OrganizationClusters, Utils } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import { TextPlacement } from '@enums/query.enum';
import useLoading from '@hooks/useLoading';
import usePagination from '@hooks/usePagination';
import useVisible from '@hooks/useVisible';
import { findMany_SuperAdmin } from '@apis/organization-clusters';
import { FilterValue } from '../components/Filter';

interface UseOrganizationClustersOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const getConditionAndCount = (filterValue: FilterValue) => {
  const { name, memberName, ...others } = filterValue;
  const condition: OrganizationClusters.FindManyQuery_SuperAdmin = {
    ...others,
    name: isNil(name)
      ? undefined
      : { text: name, placement: TextPlacement.PREFIX },
    memberName: isNil(memberName)
      ? undefined
      : { text: memberName, placement: TextPlacement.PREFIX },
  };

  const count = Object.values(condition).filter(
    (item) => !isNil(item) && !isEmpty(item),
  ).length;

  return [condition, count] as const;
};

const useOrganizationClusters = (
  props: UseOrganizationClustersOptions = {},
) => {
  const { onError, throwable } = props;
  const loading = useLoading();
  const pagination = usePagination();
  const visible = useVisible();
  const condition = useRef<OrganizationClusters.FindManyQuery_SuperAdmin>({});
  const [count, setCount] = useState(0);
  const [filterValue, setFilterValue] = useState<FilterValue>();
  const [data, setData] = useState<
    OrganizationClusters.FindManyResp_SuperAdmin[]
  >([]);

  const getAndStoreCondition = (filterValue: FilterValue) => {
    setFilterValue(filterValue);
    const [next, count] = getConditionAndCount(filterValue);
    condition.current = next;
    setCount(count);
    return next;
  };

  const search = async (
    conditions: OrganizationClusters.FindManyQuery_SuperAdmin,
    page: Utils.Pagination['page'],
    size: Utils.Pagination['size'],
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, meta] = await findMany_SuperAdmin({
          ...conditions,
          page,
          size,
        });
        setData(data);
        const { total } = meta;
        pagination.changeTotal(total);
        return data;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  const fetch = async (filterValue: FilterValue = {}) => {
    const { page, size } = pick(['page', 'size'], pagination);
    const condition = getAndStoreCondition(filterValue);
    return await search(condition, page, size);
  };

  const changePage = async (page: number, size: number) => {
    pagination.changePageAndSize(page, size);
    const data = await search(condition.current, page, size);
    return data;
  };

  const reload = async () => {
    const { page, size } = pagination;
    const data = await search(condition.current, page, size);
    return data;
  };

  const reset = async () => {
    const { page, size } = pagination.reset();
    const condition = getAndStoreCondition({});
    const data = await search(condition, page, size);
    return data;
  };

  return {
    loading: loading.loading,
    ...visible,
    filterValue,
    fetch,
    changePage,
    reload,
    reset,
    count,
    data,
    pagination,
  };
};

export default useOrganizationClusters;
