import styled from 'styled-components';
import { Form, theme } from 'antd';

export const StyledForm = styled(Form).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['padding'].includes(prop) && defaultValidatorFn(prop),
})<{ padding?: boolean }>`
  background-color: ${theme.getDesignToken().colorWhite};
  padding-bottom: ${({ padding }) => (padding ? '24px' : '0')};
`;
