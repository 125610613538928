import { AxiosResponse, CanceledError } from 'axios';
import z, { TypeOf as ZodTypeOf, ZodTypeAny, ZodArray, ZodError } from 'zod';

import { Utils } from '@interfaces/apis';
import { MetaRespSchema } from '@schemas/apis/utils.schema';

export const asyncLoadingWrapper = async <Result = void>(
  setLoading:
    | ((next: boolean) => void)
    | { start: () => void; end: () => void },
  func:
    | (() => Promise<Result>)
    | { handler: () => Promise<Result>; onFinally?: () => void },
  onError?: (error: unknown) => void,
  throwable?: boolean,
) => {
  const handler = typeof func === 'object' ? func.handler : func;
  const onFinally = typeof func === 'object' ? func.onFinally : undefined;

  try {
    if (typeof setLoading === 'object') setLoading.start();
    else setLoading(true);

    const result = await handler();
    return result;
  } catch (error: unknown) {
    onError?.(error);
    if (throwable) throw error;
  } finally {
    if (typeof setLoading === 'object') setLoading.end();
    else setLoading(false);
    onFinally?.();
  }
};

export const formatResponse = <D>(
  response: AxiosResponse<Utils.WeMoReply<D>>,
) => {
  const { data } = response.data;
  return data;
};

export const formatResponseWithMeta = <D>(
  response: AxiosResponse<Utils.WeMoReplyWithMeta<D>>,
): [D extends ArrayLike<any> ? D : D[], Utils.WeMoMeta] => {
  const { data, meta } = response.data;
  return [data, meta];
};

class SchemaNotMatchError extends Error {
  constructor(readonly error: ZodError) {
    super('SCHEMA_NOT_MATCH');
  }
}

export const serializeResponse =
  <Schema extends ZodTypeAny>(schema: Schema, printError?: boolean) =>
  <D>(response: AxiosResponse<Utils.WeMoReply<D>>): ZodTypeOf<Schema> => {
    const data = formatResponse(response);
    try {
      const result = schema.parse(data);
      return result;
    } catch (error) {
      if (printError) console.error(error);
      throw new SchemaNotMatchError(error as ZodError);
    }
  };

export const serializeManyResponse =
  <Schema extends ZodTypeAny>(schema: Schema, printError?: boolean) =>
  <D>(
    response: AxiosResponse<Utils.WeMoReplyMany<D>>,
  ): ZodTypeOf<ZodArray<Schema>> => {
    const data = formatResponse(response);
    try {
      const result = z.array(schema).parse(data);
      return result;
    } catch (error) {
      if (printError) console.error(error);
      throw new SchemaNotMatchError(error as ZodError);
    }
  };

export const serializeResponseWithMeta =
  <Schema extends ZodTypeAny>(schema: Schema, printError?: boolean) =>
  <D>(
    response: AxiosResponse<Utils.WeMoReplyWithMeta<D>>,
  ): [ZodTypeOf<ZodArray<Schema>>, Utils.WeMoMeta] => {
    const [data, meta] = formatResponseWithMeta(response);
    try {
      return [z.array(schema).parse(data), MetaRespSchema.parse(meta)];
    } catch (error) {
      if (printError) console.error(error);
      throw new SchemaNotMatchError(error as ZodError);
    }
  };

export const isAxiosCanceledError = <T>(
  error: unknown,
): error is CanceledError<T> => error instanceof CanceledError;
